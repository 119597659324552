<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <!-- selection="multiple" -->
      <c-table
        ref="table"
        :title="tableTitle"
        :columns="grid.columns"
        rowKey="dataId"
        :data="grid.data"
        :editable="editable"
        @getTableHeight="(height) => { this.imgHeight = height }"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="추가" icon="add" @btnClicked="add" />
              <c-btn
              v-show="editable && grid.data.length > 0"
              :url="insertUrl"
              :isSubmit="isSave"
              :param="grid.data"
              mappingType="POST"
              label="저장"
              icon="save"
              @beforeAction="saveItem"
              @btnCallback="saveCallback" />
            <c-btn label="검색" icon="search" @btnClicked="getList" />
            <!-- <c-btn v-if="editable && grid.data.length > 0" label="삭제" :showLoading="true" :editable="editable" icon="remove" @btnClicked="remove" /> -->
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props }">
          <q-btn 
            round unelevated 
            size="6px"
            color="amber" 
            icon="search"
            @click="preview(props.row, props.pageIndex)" />
        </template>
      </c-table>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import transactionConfig from '@/js/transactionConfig';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'emergency-relation-info-detail',
  props: {
    searchParam: {
      type: Object,
      default: () => ({
        plantCd: '1',
        dataTypeCd: '1',
        useFlag: 'Y',
      }),
    },
    tabParam: {
      type: Object,
      default: () => ({
        dataTypeCd: '1',
        title: '비상연락체계도',
      }),
    },
  },
  data() {
    return {
      file: null,
      mapSrc: require('@/assets/images/no-image2.png'),
      imgHeight: '',
      grid: {
        columns: [
          {
            required: true,
            name: 'plantCd',
            field: 'plantCd',
            label: '사업장',
            align: 'center',
            sortable: true,
            style: 'width:100px',
            type: 'plant',
          },
          {
            required: true,
            name: 'dataName',
            field: 'dataName',
            // 자료명
            label: '자료명',
            align: 'left',
            sortable: true,
            style: 'width:150px',
            type: 'text',
          },
          {
            name: 'dataDesc',
            field: 'dataDesc',
            // 자료설명
            label: '자료설명',
            align: 'left',
            sortable: true,
            style: 'width:250px',
            type: 'text',
          },
          {
            name: 'attach',
            field: 'attach',
            // 관련 파일첨부
            label: '관련 파일첨부',
            align: 'center',
            style: 'width:200px',
            type: 'attach',
            taskClassCd: 'EMERGENCY_RELATION',
            keyText: 'dataId',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            // 사용여부
            label: '사용여부',
            align: 'center',
            sortable: true,
            style: 'width:90px',
            type: 'check',
            true: 'Y',
            false: 'N',
          },
        ],
        data: [],
      },
      defaultData: [],
      editable: true,
      insertUrl: '',
      searchUrl: '',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tableTitle() {
      return this.$language(this.tabParam.title) + ' ' + this.$language('목록') // this.tabParam.title  목록
    },
    setImgHeight() {
      let returnText = '';
      returnText = this.imgHeight ? (Number(this.imgHeight.replace('px', '')) - 32) + 'px' : '500px'
      return returnText;
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.eap.relation.list.url;
      this.insertUrl = transactionConfig.sop.eap.relation.insert.url;
      this.deleteUrl = transactionConfig.sop.eap.relation.delete.url;
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    add() {
      this.grid.data.splice(0, 0, {
        plantCd: '',
        dataTypeCd: this.tabParam.dataTypeCd,
        dataId: uuidv4(),
        dataName: '',
        dataDesc: '',
        useFlag: 'Y',
        editFlag: 'C',
      })
    },
    saveItem() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.saveCallback();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    preview(row) {
      this.mapSrc = row.src
    },
    getImageData(data) {
      this.mapSrc = data;
    }
  }
};
</script>
